.main-text {
  text-shadow: 2px 2px black;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.4em;
}

.markdown-content a,
.markdown-content a:link,
.markdown-content a:visited,
.markdown-content a:active,
.markdown-content a:focus {

  color:rgb(19, 19, 146);
  text-decoration: underline;

}

.logo {
  background: rgba(0,0,0,0.2);
  border-radius: 48px;
}

header ul a {
  font-weight: bold;
  text-shadow: 1px 1px 1px black;
}