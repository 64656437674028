@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply mb-8 font-serif text-2xl leading-normal md:text-4xl;
  }

  h2 {
    @apply mt-6 mb-4 text-xl leading-normal md:text-2xl;
  }

  p {
    @apply mb-4;
  }
}

html {
  scroll-behavior: smooth;
}

.min-image-h {
  height: 50vh;
}